import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import PartList from '../components/PartList';
import SEO from "../components/seo";
import PageLayout from '../components/page-layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title={"Electronics"} mdxType="SEO" />
    <h2>{`Electronics`}</h2>
    <p>{`The idea behind AvoBot was to create a low-cost robot which would allow to experiement with ROS and do SLAM and navigation.`}<br parentName="p"></br>{`
`}{`Since it's a moving robot the wheels are kinda important, so I got JGA25-370 motors with wheels.`}<br parentName="p"></br>{`
`}{`The quality is pretty good and they come with Hall-sensor encoders with is very convenient.`}<br parentName="p"></br>{`
`}{`Initially I used L298n motor driver controller which does the job, but later switched to TB6612FNG which is much smaller and more efficient.`}<br parentName="p"></br>{`
`}{`Probably the cheapest way to run ROS on a robot is by using Raspberry Pi.`}<br parentName="p"></br>{`
`}{`AvoBot uses Raspberry Pi 3 Model B, but any other should work. Keep in mind that newer releases of ROS are only supported on `}<inlineCode parentName="p">{`arm64`}</inlineCode>{` so the RPi has to be able to run 64-bit Ubuntu.`}<br parentName="p"></br>{`
`}{`Arduino Nano is used to control the motors and read the motor encoders.`}<br parentName="p"></br>{`
`}{`Serail port pins are connected directly to RPi through a Logic Level Converter instead of a USB.`}<br parentName="p"></br>{`
`}{`It allows to save space (no need for a bulky usb connector and a cable) and leaves the usb port free, so it's more convenient to update Arduino firmware.`}<br parentName="p"></br>{`
`}{`The most expensive part of the robot is a lidar, but as far as lidars go `}<inlineCode parentName="p">{`RPLidar A1`}</inlineCode>{` is one of the cheapest ones with good software support.  `}</p>
    <p>{`Normally a separate battery is used for motors, but in my experience, a single 5v USB power bank is enough.`}<br parentName="p"></br>{`
`}{`The only caveat is that even though RPi and motor driver are powered by different USB ports it's quite likely that they are not completely decoupled in the power bank which leads to dips in voltage when the motors are starting to move at high speeds.`}<br parentName="p"></br>{`
`}{`To overcome this problem RPi power has 4 1000mF capacitors connected in parallel.`}<br parentName="p"></br>{`
`}{`This stores enough power for the short power dips.`}<br parentName="p"></br>{`
`}{`Still, if you try to run start stationary bot at 0.35m/s your RPi will restart,
but this can be easily overcome by setting speed limits in navigation configuration.  `}</p>
    <p>{`This is how everything is wired together:
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/dc1a59372f4e5cd2b534dd9f4d7c406d/1a867/wiring_schem.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "90.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAACmFAAAphQGPVf7hAAACfUlEQVQ4y3VUi5KbMAzM//9h22mby10IYAwGm6ctVK0S59G7Y8aD8WO1u5I48DfPvu+f3kT0svZ0Vhd22uPhK4DnkZ++73me5/v3tm1sG8MpJXzSbfly+A4or0/zxE1rua5rrk2dAXiLGzd1qaxj3MgPAyei8+ErCXktxcR1U/Pv40/uh55//fnBl+qsTEMIPI4jL8uCb3JtwzHF4s4QkWOMKmWQaBhFUXBVVdyINGOMyHY8TiN3rlMwAMOKdV1VMqX0AJRFpY+BC957HVVTsXOOrbUct6j7y7zoeZDAGdtaAhlZewXM3iFqGAP3oefKVtx2rXoJeRhZAeZQBMkI9AkwM8ThRjzpvQAP4cpycMpmmiZ9Q3K2iCQbmEuAByA27gwlAUM/sAuOP+wHO+8UKGf4+cE9AXtlSJT0AqJgtG2rvjlJwrk4awB4iDNQsi5XD1cBu2WakKA7oG5I9uAbDpRlyV3XqXTMAUw7aTBVcauKVc77yUu5JPJSRgJaHAC2ScReMqs+Ba/swAamg536JJLukmXupJwGSZ4X0MEYmH9lSLSzl04YUely4b08au3BQwDmetP6DAMbSVbbGr58/JWgM4NZdzxSgh3bVminzMKut41uNuaiHmZPgwD6UdtK2i1qgFGY2bc3XsRHtGB3OtGOOQpbMxWEhZQCZBlb8uCvAKsw6yRQByvEU76BklxuTydGgYC5MQWNIj1lwFzxiF511bVPA1rMyuFS5RfluyQuXBMj5+HhsmofgwAhgVuWrJlGF4jM/M/Lj2SQwxyUGWzARQT2PnDd1dw4sar3hETK3Udh//+nef6FQRbqDmBanzLQnrkFZU4oM1FZ/AN6i3auAeje5wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Wiring schematic",
            "title": "Wiring schematic",
            "src": "/static/dc1a59372f4e5cd2b534dd9f4d7c406d/c1b63/wiring_schem.png",
            "srcSet": ["/static/dc1a59372f4e5cd2b534dd9f4d7c406d/5a46d/wiring_schem.png 300w", "/static/dc1a59372f4e5cd2b534dd9f4d7c406d/0a47e/wiring_schem.png 600w", "/static/dc1a59372f4e5cd2b534dd9f4d7c406d/c1b63/wiring_schem.png 1200w", "/static/dc1a59372f4e5cd2b534dd9f4d7c406d/d61c2/wiring_schem.png 1800w", "/static/dc1a59372f4e5cd2b534dd9f4d7c406d/1a867/wiring_schem.png 1998w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/05b1953ffddd985b28ef6182a3311dad/024a2/wiring_bb.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAACmFAAAphQGPVf7hAAADfklEQVQ4y12T60+bVRjAX5hfli0xmfGDbAYzE/3gd02I8S9w+6QmxkSDSzZnnBccN0eyMQTnaAILG7dNMwi6ysaoA+K6cgullHJpV9qBFKFIYfCOUnq/vG3Pz7ct44PPyZPzPuec/N7nKqFKIBCgs7ODiQlTxiSVSjE7O4PT6cjaQgjiyThCXUpKISlS++d7Kp7vUuZiZcVNcfEZNJrG7MNgKMS5c99zvrQya6/tPqVJ24jOrKNj4DbaR79lz9MivQ/N8QVZ4Pb2JpUXPlG9zAHDIT8XL31JzY8lWXsjKFPXUoO2r4ubd1u5+7ArB1QjIcNSmZmoRFr1MC0Utj1Whh6UMOe6lwtZXUODl9Ebangu7vknxLw7xAN+UkE/g/Zhrmuvo7mv4cofV0SfqQ/9jF5Iv0+baRvo5tc/B+keszDp8mJfDTE96UA/Zd4HrrlcJFSYEg5DKEZ5fyVHywo4XnqcgooCqu5UiWpdNVLTsB77lAmn2YHJ+Jhp+xKOeTfDDw0MmcYgoaixpVm124lubBCVN1VgnJbRBqQvJA6/exjprCTyy/OpuPO1kNrHDbgdFkimUZ7KpD0yYl1m3enkrwf3cU9MsGyxMNXbi2t8nGWbDZ/6rrapCumMRF5ZPnnn88SJxhM0a2+oQKsRQ3M1I2dPoW++wMBSLza3UVUrjqV5Ujs+QrJM1OslqSZeUSubkautF3lB9VD6RuJQ+SGKLhWJutt1SJ2P/+Zm/y9cePNl6j8+SevMLPcWjIx55ljwy/xfUnt7U+fPvPT5ixwrfJW89/NEYW0h7bfahRSIqW0j+/EM9LKrVnJuPYLuiR2Dy8Zq+BlqIthNBAkkwyjpFOnwLkrCw08dlbxT9jYH3zvIgU8PCKlU4oO2D3ONTSKuVjCU/bSubdJmGaFjyohufhKbbMQbXUBOLGIdvIHpoyLGxrv5qv4z3vruDY5UHOHYD0eRTkvidFdxrrGTkQhhjycL7J+289qpEl7/9jKvVGio1fbkxiwaZ238EStdLayZR1lYNNE21ErPSA+jtlFRP1yPxTWa8zCtKASXl1G2trCOT9Jw9RrXmm6haWjD0KMjsrjIjtVK0DlP9F8PPrOFZCiKvLnNrs+X/d9eaoW0N4f7w05GI6HM/KkaVAc7QFytcMKnTsmOl5i8RTqRwOvf4Z+VJbae5QoXi8dEJBLmP8GrmOpYFi3sAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Wiring breadboard",
            "title": "Wiring breadboard",
            "src": "/static/05b1953ffddd985b28ef6182a3311dad/c1b63/wiring_bb.png",
            "srcSet": ["/static/05b1953ffddd985b28ef6182a3311dad/5a46d/wiring_bb.png 300w", "/static/05b1953ffddd985b28ef6182a3311dad/0a47e/wiring_bb.png 600w", "/static/05b1953ffddd985b28ef6182a3311dad/c1b63/wiring_bb.png 1200w", "/static/05b1953ffddd985b28ef6182a3311dad/d61c2/wiring_bb.png 1800w", "/static/05b1953ffddd985b28ef6182a3311dad/024a2/wiring_bb.png 2199w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Fritzing files and the code are `}<a parentName="p" {...{
        "href": "https://github.com/Leonti/ros-rover"
      }}>{`here`}</a>{`  `}</p>
    <h3>{`List of parts used in the build:`}</h3>
    <PartList mdxType="PartList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      